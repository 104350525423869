<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-end">
        <div
          class="d-flex justify-content-end align-items-center mb-2"
          style="width:26%"
        >
          <flat-pickr
            v-model="rangeDate"
            class="form-control"
            placeholder="开始时间 ~ 结束时间"
            :config="{ mode: 'range'}"
            @on-change="handleRangeChange"
          />
          <span
            class="search-text"
            @click="handleSerach"
          >搜索</span>
        </div>
      </div>
      <div
        v-if="isLoading"
        class="d-flex justify-content-center align-items-center"
      >
        <b-spinner
          type="grow"
          variant="warning"
          class="mr-1"
        />
        <span class="loading-text">Loading...</span>
      </div>
      <b-table
        v-else
        responsive
        bordered
        :items="items"
        :fields="fields"
      >
        <template #cell(date)="data">
          <span class="text-nowrap">
            {{ data.item.week_start }} - {{ data.item.week_end }}
          </span>
        </template>
        <template #cell(shipment_cost)="data">
          <span class="text-nowrap">
            AU${{ data.item.shipment_cost }}
          </span>
        </template>
        <template #cell(action)="data">
          <span
            class="watch"
            @click="handleWatch(data.item)"
          >查看</span>
          <span
            class="watch ml-1"
            @click="handleExport(data.item)"
          >导出详情</span>
        </template>
      </b-table>
      <!-- <b-row class="mt-2">
        <b-col>
          <b-form-group
            cols="2"
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col cols="10">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            class="my-0"
          />
        </b-col>
      </b-row> -->
    </b-card>
  </div>
</template>

<script>
import {
  BTable, BCard,
  // BPagination, BFormGroup, BFormSelect, BRow, BCol,
  BSpinner,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import dayjs from 'dayjs'

export default {
  components: {
    BTable,
    BCard,
    // BPagination,
    // BFormGroup,
    // BFormSelect,
    // BRow,
    // BCol,
    flatPickr,
    BSpinner,
  },
  data() {
    return {
      rangeDate: '',
      isLoading: false,
      fields: [
        {
          key: 'date', label: '日期', sortable: true,
        },
        {
          key: 'shipment_amount', label: '运单数量', sortable: true,
        },
        {
          key: 'shipment_cost', label: '运单金额', sortable: true,
        },
        {
          key: 'action', label: '操作',
        },
      ],
      items: [],
      currentPage: 1,
      totalRows: 10,
      perPage: 10,
      pageOptions: [5, 10, 15, 20],
      searchObj: {},
    }
  },
  created() {
    this.handleGetList()
  },
  methods: {
    handleSerach() {
      this.handleGetList()
    },
    handleRangeChange(e) {
      this.searchObj = {
        week_start: dayjs(e[0]).format('YYYY-MM-DD'),
        week_end: dayjs(e[1]).format('YYYY-MM-DD'),
      }
    },
    handleExport(item) {
      this.$store.dispatch('shipment/handleExport', item)
    },
    async handleGetList() {
      try {
        const value = localStorage.getItem('userInfo')
        const userInfo = JSON.parse(value)
        this.isLoading = true
        const data = await this.$api.statisticsList({
          page: this.currentPage,
          page_size: this.perPage,
          agent_id: userInfo.id,
          ...this.searchObj,
        })
        this.isLoading = false
        this.items = data.data
        this.totalRows = data.data.length
      } catch (error) {
        this.isLoading = false
        console.log('error', error)
      }
    },
    handleWatch(item) {
      localStorage.setItem('statisticsDate', JSON.stringify(item))
      this.$router.push({ name: 'statisticsdetail' })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.watch{
  cursor: pointer;
  font-size: 18px;
  color: #FFAF2A;
}
.loading-text{
  font-size: 26px;
}
.search-text{
  min-width:40px;
  margin-left:10px;
  color: #FFAF2A;
  cursor: pointer;
}
</style>
